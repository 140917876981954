<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4" class="text-center">
        <!--カバー画像-->
        <v-row>
          <v-col cols="12" class="text-center">
            <h2>肖像抽出</h2>
            <p>画像を選択して、抽出肖像を表示する。</p>
            <v-alert dense border="left" type="warning">
              注意：画像より肖像の抽出はちょっと時間かかるので、少々お待ちください。
            </v-alert>
            <v-card class="mx-auto">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img contain :src="coverImg"></v-img>
            </v-card>
          </v-col>
        </v-row>
        <!--ファイルアップロード-->
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <v-btn
              color="primary"
              class="text-none"
              rounded
              depressed
              @click="onUpload"
            >
              <v-icon left> mdi-cloud-upload </v-icon>
              {{ buttonText | ellipsis }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            />
          </v-col>
        </v-row>
        <!--結果領域-->
        <v-row v-if="portraitImg">
          <v-col cols="12" class="text-center">
            <v-card class="mx-auto">
              <v-card-title>検出情報：</v-card-title>
              <v-img contain :src="portraitImg"></v-img>
              <v-card-actions>
                <v-btn
                  color="primary"
                  class="text-none"
                  rounded
                  @click="onDownload"
                >
                  <v-icon left> mdi-cloud-download </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--サンプル画像-->
    <v-row v-if="sampleImgs.length > 0">
      <v-col
        cols="4"
        sm="3"
        md="2"
        lg="1"
        class="text-center"
        v-for="(img, index) in sampleImgs"
        :key="img"
      >
        <v-card class="mx-auto" max-width="100">
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img height="100" :src="img" @click="segmentSample(index)"></v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import baseUrl from "@/api/baseUrl";
import { init, segmentSample, segmentImage } from "@/api/bodyAnalysis";
export default {
  data: () => ({
    defaultButtonText: "画像をアップロード",
    selectedFile: null,
    isSelecting: false,
    coverImg: require("../assets/img/bda/sp-cover.png"),
    sampleImgs: [],
    portraitImg: null,
    overlay: false,
  }),
  mounted: function () {
    init().then((res) => {
      if (res.data) {
        this.sampleImgs = res.data.map((img) => baseUrl + img);
      }
    });
  },
  computed: {
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },
  },
  methods: {
    onUpload() {
      this.isSelecting = true;
      this.$refs.uploader.click();
    },
    onDownload() {
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      downloadLink.href = this.portraitImg;
      downloadLink.target = "_self";
      downloadLink.download = "portraitImg";
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      if (this.selectedFile) {
        this.overlay = true;
        this.portraitImg = null;
        var reader = new FileReader();
        reader.onload = (e) => {
          this.coverImg = e.target.result;
        };
        reader.readAsDataURL(this.selectedFile);
        this.isSelecting = false;
        segmentImage(this.selectedFile)
          .then((res) => {
            this.overlay = false;
            this.portraitImg = "data:image/png;base64," + res.data.resultImage;
          })
          .catch((error) => {
            this.overlay = false;
            console.error(error);
          });
      }
    },
    segmentSample(index) {
      this.selectedFile = null;
      this.overlay = true;
      this.portraitImg = null;
      this.coverImg = this.sampleImgs[index];
      segmentSample(index + 1)
        .then((res) => {
          this.overlay = false;
          this.portraitImg = "data:image/png;base64," + res.data.resultImage;
        })
        .catch((error) => {
          this.overlay = false;
          console.error(error);
        });
    },
  },
  filters: {
    ellipsis(value) {
      let len = value.length;
      if (!value) return "";
      if (value.length > 20) {
        return value.substring(0, 8) + "..." + value.substring(len - 8, len);
      }
      return value;
    },
  },
};
</script>

<style>
.v-icon--left {
  margin-right: 8px;
}
.dager {
  background: #f44336;
}
.waring {
  background: #ff9800;
}
</style>