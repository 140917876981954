import request from "./request"

export const init = () =>{
    return request.get("/bda")
}

export const segmentSample = (index) =>{
    return request.get("/bda/sample/"+index)
}

export const segmentImage = (file) =>{
    let formData = new FormData();
    formData.append("file", file);
    return request.post("/bda/segment",formData,{
        headers: {
            "Content-Type": "multipart/form-data",
          },
    })
}